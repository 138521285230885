<template>
  <section class="mt-4 mb-4">
    <h3 class="has-text-black mt-4 mb-4">FAQs</h3>
    <div class="columns">
      <div class="column is-half">
        <b-collapse
          class="card"
          animation="slide"
          v-for="(collapse, index) of collapses"
          :key="index"
          :open="isOpen == index"
          @open="isOpen = index"
        >
          <template #trigger="props">
            <div class="card-header" role="button">
              <p class="card-header-title">
                {{ collapse.title }}
              </p>
              <a class="card-header-icon">
                <b-icon
                  pack="fas"
                  :icon="props.open ? 'arrow-up' : 'arrow-down'"
                >
                </b-icon>
              </a>
            </div>
          </template>
          <div class="card-content">
            <div class="content" v-html="collapse.text"></div>
          </div>
        </b-collapse>
      </div>
      <div class="column is-half is-hidden-mobile">
        <img
          src="https://ticketingsolutions.canto.global/direct/image/2aq31qslv976rarpfrfjcst976/B1zAZjnL-16jHgUAh-uGj2NDwBU/m800/800"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FAQ",
  components: {},
  data() {
    return {
      isOpen: 0,
      collapses: [
        {
          title: "iec-karte – was ist das?",
          text: "<p>Die iec-karte ist das elektronische Zahlungsmittel der Roosters bei unseren Heimspielen in der Eissporthalle Iserlohn. Mit dem Guthaben auf der Karte könnt Ihr Speisen und Getränke kaufen.</p>",
        },
        {
          title: "Welche Vorteile bringt mir die iec-karte?",
          text: "<p>Ihr bekommt bei Zahlung mit der iec-karte bei jedem Kauf von Speisen und Getränken in der Eissporthalle automatisch 3% Rabatt.</p><p>Außerdem sammelt Ihr bei jeder Zahlung mit der iec-karte automatisch Bonuspuck für Euer Kundenkonto, die Ihr für interessante Prämien eintauschen könnt. Pro 2€ Einkaufswert erhaltet Ihr einen Bonuspuck. Fleißig Sammeln lohnt sich!</p><p>Wenn Ihr noch kein Roosters-Kundenkonto habt (Kleine Strafe wegen unsportlichen Verhaltens!), könnt Ihr Euch unkompliziert eins anlegen. Ein Roosters-Kundenkonto ist selbstverständlich kostenlos.<br /><strong>Fügt die Website der iec-karte am Besten direkt als Shortcut auf den App-Bildschirm Eures Handys hinzu!</strong> Natürlich könnt Ihr auch mehrere iec-Karten unter einem Account verknüpfen - erleichtert das Punkte sammeln.</p><p><strong>Übrigens</strong>: Wenn Ihr ein Konto verknüpft habt, könnt Ihr Euch den QR-Code Eurer iec-Karte auch im Browser auf dem Handy anzeigen lassen – Ihr müsst Euch also nicht jedes Mal eine neue Karte holen, falls Ihr sie grade nicht dabei habt.</p>",
        },
        {
          title: "Wo bekomme ich die iec-karte?",
          text: "<p>Die iec-karte ist bei unseren Heimspielen an der Abendkasse sowie an der ausgeschilderten Station in der Pistenbar erhältlich und kann dort mit EC-, Kreditkarte oder auch mit Bargeld aufgeladen werden. Euer Guthaben könnt ihr auch unkompliziert im Bereich der Grill-Box hinter der Eissporthalle erhalten. Hier ist jedoch nur eine Zahlung mit EC- oder Kreditkarte möglich.<p></p>Außerhalb der Spiele kannst Du die iec-Karte in der Geschäftsstelle erwerben und aufladen.</p>",
        },
        {
          title: "Wie kann ich die iec-karte aufladen?",
          text: "<p>Sobald Dein Kundenkonto mit der iec-karte verknüpft ist, kannst Du die iec-karte ganz bequem jederzeit online aufladen: Das funktioniert im Browser über den Kundenbereich Deiner iec-karte.</p><p>Du kannst die iec-karte aber auch mit Bargeld und per EC-/Kreditkarte bei unseren Heimspielen an der Tageskasse oder den Stationen in und hinter der Halle aufladen. Außerhalb des Spielbetriebs kannst Du die Karte auch im Hockeystore in der Geschäftsstelle aufladen.</p><p>Bei der Aufladung in der Halle musst Du mindestens 5,00 EUR aufladen, online sind es mindestens 20,00 EUR. Für die Onlinebezahlung musst du die iec-karte mit deinem Kundenkonto verknüpfen.<br/>Sämtliche Umsätze, das heißt Aufladungen und auch getätigte Käufe hast Du über den entsprechenden Punkt im Menü Deiner iec-Karte jederzeit im Blick.</p>",
        },
        {
          title: "Was kostet die iec-karte?",
          text: "<p>Auf der iec-karte ist ein Pfand in Höhe von 2,00 EUR. Diesen bekommst du bei Rückgabe der Karte im Hockeystore wieder zurück. <br/>Hast Du die Karte kostenfrei erhalten, entfällt natürlich Dein Anspruch auf die Rückzahlung des Pfandbetrages.</p>",
        },
        {
          title: "Was passiert beim Verlust der Karte?",
          text: "<p>Wir empfehlen, die iec-karte direkt mit Deinem Kundenkonto zu verknüpfen. Dann kannst Du über Deinen Kundenbereich die verknüpfte Karte direkt online sperren. Für die Verknüpfung mit einer neuen Karte wende Dich bitte unter der Woche an die Mitarbeiter*innen in der Geschäftsstelle.</p><p>Sollte deine iec-karte verloren gehen, ohne dass Du sie mit deinem Kundenkonto verknüpft hast, ist das Guthaben leider nicht wiederherstellbar. Das gilt ebenso für Missbrauch der Karte durch Dritte, weswegen wir darauf hinweisen, den QR-Code nicht abzufotografieren oder im Internet zu veröffentlichen – und natürlich gut auf Deine iec-karte aufzupassen.</p>",
        },
        {
          title: "Kann ich auch bar oder mit EC-Karte zahlen?",
          text: "<p>Wann immer Du während unserer Heimspieltage Essen oder Getränke kaufen möchtest, hast die Wahl mit EC-/Kreditkarte oder iec-karte zu bezahlen.</p><p>Möchtest Du Guthaben auf Deine iec-karte laden, funktioniert das neben den erwähnten Methoden auch mit Bargeld. Für eine schnelle und unkomplizierte Abwicklung ist das nur passend in Scheinen möglich.</p>",
        },
        {
          title:
            "Kann ich mir das Guthaben auf der iec-karte wieder auszahlen lassen?",
          text: "<p>Die iec-karte ist ein Wertgutschein und kann somit nicht wieder ausgezahlt werden.</p>",
        },
      ],
    };
  },
};
</script>

<style></style>
